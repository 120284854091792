import React, { useState, useEffect } from "react";
import { InputDiv } from "./Components";

export default function TextArea(props) {
  const [state, setState] = useState("");

  return (
    <InputDiv className={state}>
      <textarea
        onSelect={() => setState("input--filled")}
        onBlur={() => props.value === "" && setState("")}
        {...props}
      />
      <label className='input__label'>
        <span className='input__label-content' data-content={props.label}>
          {props.label}
        </span>
      </label>
    </InputDiv>
  );
}
