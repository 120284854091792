import React, { useState, useEffect } from "react";
import { InputDiv } from "./Components";

export default function Input(props) {
  const [state, setState] = useState("");

  useEffect(() => {
    setState("");
  }, [props.disabled]);

  return (
    <InputDiv className={state}>
      <input
        style={props.style}
        onSelect={() => setState("input--filled")}
        onBlur={() => props.value === "" && setState("")}
        {...props}
      />
      <label className='input__label'>
        <span
          className='input__label-content'
          data-content={props.disabled ? "" : props.label}
        >
          {props.disabled ? "" : props.label}
        </span>
      </label>
    </InputDiv>
  );
}
