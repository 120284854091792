import React, { useState } from "react";
import "../styles/styles.css";
import { Container, Form, Row } from "../components/Components";
import TextArea from "../components/TextArea";
import Input from "../components/Input";

const IndexPage = () => {
  const [puheenjohtaja, setPuheenjohtaja] = useState("");
  const [sihteeri, setSihteeri] = useState("");
  const [sijainti, setSijainti] = useState("");
  const [avaus, setAvaus] = useState("");
  const [laillisuusJaPäätösvaltaisuus, setLaillisuusJaPäätösvaltaisuus] =
    useState("");
  const [ilmoitusasiatJaPosti, setIlmoitusasiatJaPosti] = useState("");
  const [
    uusienKannatusjäsentenHyväksyminen,
    setUusienKannatusjäsentenHyväksyminen,
  ] = useState("");
  const [muutEsilleTulevatAsiat, setMuutEsilleTulevatAsiat] = useState("");
  const [päättäminen, setPäättäminen] = useState("");

  const [buttonStyle, setButtonStyle] = useState("");
  function getClipboard() {
    if (buttonStyle === "") {
      setButtonStyle("copied");
      setTimeout(() => {
        setButtonStyle("");
      }, 3000);
    }

    var headers = "";
    var pöytäkirja = "";
    headers += `Puheenjohtaja: ${puheenjohtaja}\nSihteeri: ${sihteeri}\nSijainti: ${sijainti}`;
    headers += "\n\n";
    pöytäkirja += `1. Kokouksen avaus\n${avaus}\n\n2. Kokouksen laillisuus ja päätösvaltaisuus\n${laillisuusJaPäätösvaltaisuus}\n\n`;
    pöytäkirja += `3. Ilmoitusasiat ja posti\n${ilmoitusasiatJaPosti}\n\n4. Uusien kannatusjäsenten hyväksyminen\n${uusienKannatusjäsentenHyväksyminen}\n\n`;
    pöytäkirja += `5. Muut esille tulevat asiat\n${muutEsilleTulevatAsiat}\n\n6. Kokouksen päättäminen\n${päättäminen}\n\n`;
    return headers + pöytäkirja;
  }
  return (
    <Container>
      <title>Gambinakokous</title>
      <h1>Gambinakokous</h1>
      <h2>
        Tee kokouspöytäkirja tähän ja kopioi se leikepöydälle alhaalta
        löytyvästä napista.
      </h2>
      <Form>
        <Row>
          <Input
            label='Puheenjohtaja'
            type='text'
            name='Puheenjohtaja'
            required
            onChange={(event) => setPuheenjohtaja(event.target.value)}
            value={puheenjohtaja}
            disabled={false}
          />
          <div style={{ width: 64 }} />
          <Input
            label='Sihteeri'
            type='text'
            name='Sihteeri'
            required
            onChange={(event) => setSihteeri(event.target.value)}
            value={sihteeri}
            disabled={false}
          />
        </Row>
        <Input
          label='Sijainti'
          type='text'
          name='Sijainti'
          required
          onChange={(event) => setSijainti(event.target.value)}
          value={sijainti}
          disabled={false}
        />
        <TextArea
          label='1. Kokouksen avaus'
          required
          onChange={(event) => setAvaus(event.target.value)}
          style={{ resize: "none" }}
          rows='8'
          value={avaus}
          disabled={false}
        />
        <TextArea
          label='2. Kokouksen laillisuus & päätösvaltaisuus'
          required
          onChange={(event) =>
            setLaillisuusJaPäätösvaltaisuus(event.target.value)
          }
          style={{ resize: "none" }}
          rows='8'
          value={laillisuusJaPäätösvaltaisuus}
          disabled={false}
        />
        <TextArea
          label='3. Ilmoitusasiat & posti'
          required
          onChange={(event) => setIlmoitusasiatJaPosti(event.target.value)}
          style={{ resize: "none" }}
          rows='8'
          value={ilmoitusasiatJaPosti}
          disabled={false}
        />
        <TextArea
          label='4. Uusien kannatusjäsenten hyväksyminen'
          required
          onChange={(event) =>
            setUusienKannatusjäsentenHyväksyminen(event.target.value)
          }
          style={{ resize: "none" }}
          rows='8'
          value={uusienKannatusjäsentenHyväksyminen}
          disabled={false}
        />
        <TextArea
          label='5. Muut esille tulevat asiat'
          required
          onChange={(event) => setMuutEsilleTulevatAsiat(event.target.value)}
          style={{ resize: "none" }}
          rows='8'
          value={muutEsilleTulevatAsiat}
          disabled={false}
        />
        <TextArea
          label='6. Kokouksen päättäminen'
          required
          onChange={(event) => setPäättäminen(event.target.value)}
          style={{ resize: "none" }}
          rows='8'
          value={päättäminen}
          disabled={false}
        />
      </Form>
      <button
        onClick={() => navigator.clipboard.writeText(getClipboard())}
        disabled={false}
        className={buttonStyle}
      >
        {buttonStyle === "copied" ? "Kopioitu!" : "Kopioi leikepöydälle"}
      </button>
    </Container>
  );
};

export default IndexPage;
